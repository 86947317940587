import React, { useState } from "react";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <img className="logo" alt="logo" src="./assets/logo.png" />
      <div className="title">卡士大抽獎 賞你飛 LUCKY DRAW</div>

      <div className="kv-tnc-container">
        <div className="kv-container">
          <img className="kv" alt="kv" src="./assets/kv.jpg" />
        </div>
        <div className="tnc-container">
          <div className="tnc-promotion">推廣詳情</div>
          <div className="tnc-promotion-separater"></div>
          <div className="whatsapp-media-container">
            <div className="none-tag"></div>
            <div className="whatsapp-container">
              <a
                className="whatsapp-button"
                href="https://wa.me/message/AIVL46ENRLF5L1"
                target="_blank"
                rel="noopener"
                title="WhatsApp"
              >
                <div className="whatsapp-text">點擊此處參加</div>
                <img
                  className="whatsapp-icon"
                  alt="whatsapp"
                  src="./assets/whatsapp.png"
                ></img>
              </a>
            </div>
            <div className="media-container">
              <a
                href="https://www.facebook.com/classykiss.hk/?locale=zh_HK"
                rel="noopener"
                target="_blank"
                title="Facebook"
              >
                <img
                  className="icon"
                  alt="facebook"
                  src="./assets/facebook.png"
                />
              </a>
              <a
                href="https://www.instagram.com/classykiss.hk/"
                rel="noopener"
                target="_blank"
              >
                <img
                  className="icon"
                  alt="instagram"
                  src="./assets/instagram.png"
                  title="Instagram"
                />
              </a>
            </div>
          </div>
          <div className="tnc-promotion-periods">
            <div className="promotion-text">活動日期 :</div>
            <div className="date-container">
              <div className="date">06</div>
              <div className="month">十二月</div>
              <div className="year">2024年</div>
            </div>
            <div className="promotion-period-icon"></div>
            <div className="date-container">
              <div className="date">02</div>
              <div className="month">一月</div>
              <div className="year">2025年</div>
            </div>
          </div>
          <div className="tnc-title">
            ［CLASSY·KISS 卡士乳酪賞你飛大抽獎！］
          </div>
          <div className="tnc-subtitle">活動條款及細則：</div>
          <ol>
            <li>
              參加者必須同意及遵守是次活動之條款及細則。此活動只適用於香港地區。
            </li>
            <li>
              此活動由2024年12月6日至2025年1月2日止，活動登記期由2024年12月6日00:00:00直至2025年1月3日23:59:59
              (Hong Kong time
              GMT+8)，登記抽獎截止日期為1月3日或之前，並以本活動使用之登記手機WhatsApp應用程式/伺服器接收時間為準，逾期登記，恕不接受。
            </li>
            <li>
              參加者須於指定商戶（香港地區的百佳超級市場、fusion、Taste
              、PARKNSHOP INTERNATIONAL、Fresh x Taste、惠康超級市場、Market
              Place by Jasons、Market Place 、3hreesixty、U購及FRESH
              新鮮生活）購買卡士乳酪產品*滿港幣$80或以上(以單一機印收據計算)，發送符合資格的收據圖片及資料（商戶名稱、收據號碼、購買日期、購買產品名稱、購買金額、身份證上的英文姓氏、身分證頭4位數字、聯絡電話號碼及電郵地址）到WhatsApp號碼8419-0092參加抽獎，成功登記即有機會參加抽獎贏取獎品。卡士乳酪產品包括:「007益生菌乳酪(家庭裝和迷你裝)」、「007無蔗糖益生菌家庭裝乳酪(家庭裝和迷你裝)」、「餐後一小時」、「原態乳酪」、「3.3g鮮酪乳」及「斷糖日記」。
            </li>
            <li>
              參加者必須保留已登記之有效收據正本作日後領獎及核實之用。購買金額以扣除所有折扣、優惠(包括信用卡即減或折扣優惠)及/或使用優惠券後最終付款價錢計算。
            </li>
            <li>
              有效收據日期必須為2024年12月6日至2025年1月2日內發出，收據需清楚印有商戶名稱、購買日期、交易編號、所購買之卡士乳酪產品名稱、價格、數量及購買總金額方為有效。若發現任何不完整、偽造、損壞、塗改、無法辨識、影印本或重複的收據均當作無效，本公司將取消其參加或得獎資格。
            </li>
            <li>
              每人參加次數不限，惟每名參加者限中獎一次。參加資格以單一收據計算，相同收據重複登記將被視作無效。
            </li>
            <li>
              是次活動由瑪希有限公司主辦 （下稱「本公司」），而Sunshine
              Marketing Group
              Limited負責活動營運（下稱「活動營運單位」）。如對是次活動有任何查詢，請直接聯絡活動營運單位：Sunshine
              Marketing Group Limited 辦公時間為星期一至五，早上10時至下午1時；
              下午2時至6時，公眾假期除外 客戶服務熱線: 8419-0092
            </li>
            <li>
              參加者於登記完畢後，即視為已閱讀所有條款及細則，並了解及同意遵守所有條款及細則之內容。如有違反，主辦單位有權取消其參加或得獎資格，並對於任何破壞是次活動之行為保留追究權利。
            </li>
            <li>
              WhatsApp為第三方通訊平台，主辦單位並不就此等通訊平台的內容或維護負上任何責任。以上通訊平台的所有內容均受其政策
              (包括但不限於私隱政策)所約束。除本文所列的條款及細則外，參加者知悉上述第三方或有其本身的條款及細則，且參加者在使用第三方通訊平台即視作參加者將受到第三方的條款及細則約束。
            </li>
            <li>
              參加者必須遵守本公司及活動營運單位所訂立的規則、條款及細則，並保證所提供之資料均為真實且正確，而且並未冒用或盜用任何第三方之資料、如違規者，主辦單位有權取消其參加及得奬資格。{" "}
            </li>
            <li>
              本活動只接受在香港特別行政區內的指定零售點發出的有效收據。指定商戶包括
              以下零售商店: 香港地區的百佳超級市場、fusion、Taste 、PARKNSHOP
              INTERNATIONAL、Fresh x Taste、惠康超級市場、Market Place by
              Jasons、Market Place 、3hreesixty、U購及FRESH 新鮮生活。
            </li>
            <li>
              若活動所填寫之資料不實、不完整或不正確，導致得獎者無法收到得獎通知或獎品，本公司一概不負責，亦不會補發獎品。
            </li>
            <li>
              任何因電腦或手機網路等技術問題而引致參加者於WhatsApp所遞交的資料有延遲、遺失、錯誤及無法辨識等情況，本公司一概不負責。
            </li>
            <li>
              參加者登記的資料將不可更改，所有資料均以WhatsApp登記之訊息記錄為準，錯誤或不完整的資料將被視作無效。
            </li>
            <li>
              如有任何爭議，本公司保留最終決定權及解釋權，並具有約束力，參加者不得異議。{" "}
            </li>
            <li>推廣生意的競賽牌照號碼：59441</li>
          </ol>
          <br></br>
          <div className="tnc-subtitle">獎品換領細則：</div>
          <ol>
            <li>
              本公司將根據伺服器接收訊息時間，確認於2025年1月3日23時59分59秒或之前正確完成所有步驟登記之參加者（「合資格參加者」）的名單，並在合資格參加者中以電腦隨機形式進行抽獎，合資格參加者有機會贏取以下獎品：
              <ul>
                <li>頭獎（共6份）：東京單人來回機票 (價值HK$5,000)</li>
                <li>
                  二獎（共30份）：Marathon Sport
                  馬拉松運動店舖現金禮券(價值HK$500)
                </li>
                <li>三獎（共100份）：包點先生現金券 (價值HK$40)</li>
              </ul>
            </li>
            <li>
              得獎名單將於2025年1月13日在「Classy Kiss
              卡士香港」Facebook專頁及星島日報及英文虎報公布。
            </li>
            <li>
              得獎者將會於活動結束後15個工作天內，收到專人透過WhatsApp登記所顯示的手提電話號碼以WhatsApp通知領獎詳情。得獎者須於指定領獎限期內在領獎，如逾期仍未領獎，將作棄權論。{" "}
            </li>
            <li>
              得獎者必須保留正本收據作領獎及核實之用。所有得獎者於領獎時須出示已登記的身份證証明文件及有效單一機印收據正本（收據須清晰顯示與本公司登記資料相符），方可領獎，否則得獎資格將被取消。{" "}
            </li>
            <li>
              得獎者必須於指定時間內到九龍尖沙咀東部麼地道77號華懋廣場8樓，得獎者須出示已登記的身份證証明文件及有效單一機印收據正本（收據須清晰顯示與本公司登記資料相符），方可領獎，否則得獎資格將被取消。如得獎者未能親身領獎，可委託代領者攜帶已簽署之授權書、收據正本及得獎者之身份證副本作領獎之用。如未有於指定時間內到指定地點領取獎品，其得獎資格將被自動取消而不獲另行通知。{" "}
            </li>
            <li>
              所有獎品一經領取，不得更換、退回、作價銷售、兌換現金或其他禮品，若有損壞、遺失或被竊，本公司恕不負責。
            </li>
            <li>
              所有獎品只適用於優惠券上所載之指定日子內使用，逾期無效。獎品之使用須受供應商之相關條款及細則約束。得獎者須於使用服務前閱覽及了解該條款，若有疑問，請與供應商直接聯絡。{" "}
            </li>
            <li>
              本公司並非獎品之供應商，故不會承擔任何關於獎品之責任，包括但不限於獎品之供應、品質及／或相關服務的責任。如獎品供應商無法如期供應上述獎品，本公司有權延遲獎品派發日期。個別獎品或受條款及細則約束，詳情請參閱個別奬品上的有關條款內容。獎品供應商有權因應實際的營運狀況而作出相應安排，請直接與獎品供應商查詢，本公司不會承擔任何責任。{" "}
            </li>
            <li>
              如本公司發現任何人士使用或教唆他人使用不正當或欺詐手段干擾、破壞或影響本活動的運作，本公司有權將相關參加者的登記取消，並保留向有關人士追究的權利。
            </li>
            <li>
              香港航空、Marathon Sports
              馬拉松及包點先生並未贊助、保證或管理本活動，亦與本活動無任何關聯。
            </li>
            <li>
              如有任何異議，本公司保留最終決定及解釋權。 獎品換領地點及時間：
              九龍尖沙咀東部麼地道77號華懋廣場8樓 星期一至五
              上午9時30分至12時00分 ，下午2時至6時
            </li>
            <li>
              本條款及細則受香港特別行政區法律管轄，並按香港法律解釋，及應服從香港特別行政區法院的非專屬司法管轄。
            </li>
            <li>
              若本條款及細則內之任何條款，無論是該條款的全部或任何部分於任何法例或法律規則下被認定為不合法、無效或不能被強制執行的，該條款之全部或部分則不會被視為本條款及細則之⼀部分，而本條款及細則其他部分之合法性、有效性或強制執行性將不受影響。{" "}
            </li>
            <li>
              任何並非本條款及細則⼀方的人士、商號、公司或法團，均無權根據《合約（第三者權利）條例》（香港法例第
              623
              章）強制執行本條款及細則的任何條款，除非本條款及細則另有明文規定，但不影響本條款及細則中規定的本公司的任何權利，而該等權利亦適用於該第三者，並可由或針對該第三者強制執行，而無需訴諸《合約（第三者權利）條例》。
            </li>
            <li>
              本條款及細則(包括其附件，如有)記述各方訂立之全部協議。本協議取代所有先前之安排、協議、承諾及口頭或書面協議。
            </li>
          </ol>
          <div className="tnc-subtitle">
            私隱政策聲明個人資料：
            <br></br>
            閣下乃自願提供個人資料予瑪希有限公司（「本公司」）。若閣下提供的資料不足，本公司可能無法處理或接納閣下提交的要求、申請或參與。
          </div>
          <ol>
            <li>
              收集目的向閣下收集的個人資料將會被使用於執行是次推廣活動及作聯絡參加者及領獎時核實身份之用途。本公司將不會在未得到閣下同意的情況下，使用或向任何第三者提供閣下的個人資料作直接促銷或與是次推廣活動非相關用途。為參加及／或領獎之需要，閣下必須提供參加方法內所要求的個人資料，包括香港身份證上的英文姓氏、聯絡電話及電郵地址。所有獎品的的得獎者須提供其身份證上的英文姓名、聯絡電話及WhatsApp得獎訊息作領獎時核實身份用途。若閣下未能提供相關資料，將使本公司無法為閣下登記參加是次推廣活動及／或就是次推廣活動與得奬者聯繫，因而使閣下無法參加是次推廣活動及／或領獎。閣下所提供之個人資料將絕對保密並於活動完結後
              2 個月內被刪除。
            </li>
            <li>
              參加者明白一切經由網絡傳遞的訊息的保密性非完全由主辦單位及營運單位所能控制，不能保證絕對安全機密，例如第三者從電腦網絡以不法途徑竊取，故參加者同意本公司及Sunshine
              Marketing Group
              Limited（「活動營運單位」）無須對其個人資料的安全負責；本公司及活動營運單位於成功接收參加者資料後必須妥為保存，並使用本公司及活動營運單位認知中及合理範圍內最佳之保密方法，盡量避免參加者的資料外洩。
            </li>
            <li>
              如有任何因電腦、網絡、電話、技術或任何理由，而令任何人士所傳送、寄出、登記之資料或令本公司及活動營運單位所傳送或寄出之通知有延遲、遺失、錯誤、無法辨識或損毀之情況，本公司及活動營運單位將不會負任何法律責任。
            </li>
            <li>
              個人資料查詢及查閱的權利,
              可以WhatsApp方式發送至8419-0092，要求查閱或更正閣下的個人資料。
            </li>
          </ol>
          <div className="tnc-promotion-separater"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
